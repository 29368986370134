import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconUrssaf: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      d="M1.66699 5.83047C1.66699 8.12958 3.54007 9.99425 5.84957 9.99425C8.15907 9.99425 10.0322 8.12958 10.0322 5.83047C10.0322 3.53135 8.15907 1.66669 5.84957 1.66669C3.53848 1.66669 1.66699 3.53135 1.66699 5.83047Z"
      fill="#1ECAD3"
    />
    <path
      d="M10.0005 9.99414H18.3035C18.3035 14.594 14.586 18.3217 10.0005 18.3217V9.99414Z"
      fill="#90B3E6"
    />
    <path
      d="M18.3337 1.66669V9.99425H10.0322C10.0322 5.39443 13.7481 1.66669 18.3337 1.66669Z"
      fill="#0071CE"
    />
    <path
      d="M10.0003 9.99414H1.69727C1.69727 14.594 5.41476 18.3217 10.0003 18.3217V9.99414Z"
      fill="#D0DDF4"
    />
  </BaseSvg>
);

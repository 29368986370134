import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconSherpaGreen: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9981 3H5.6932C4.20579 3 3 4.20539 3 5.69231C3 7.17923 4.20579 8.38461 5.6932 8.38461H16.9981C16.1816 6.68254 16.1816 4.70208 16.9981 3Z"
      fill="#51C088"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00195 17H14.3069C15.7943 17 17.0001 15.7946 17.0001 14.3077C17.0001 12.8207 15.7943 11.6154 14.3069 11.6154H3.00195C3.81843 13.3174 3.81843 15.2979 3.00195 17H3.00195Z"
      fill="#51C088"
    />
  </BaseSvg>
);

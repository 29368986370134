import { FC } from 'react';
import { useRouter } from 'next/router';
import { CSM_PHONE_NUMBER, SALES_PHONE_NUMBER } from 'src/constants';

import useAuthentication from '@/components/Authentication/useAuthentication';
import PhoneNumberButton, {
  SalesPhoneNumberButtonProps,
} from '@/components/SalesPhoneNumber/PhoneNumberButton/PhoneNumberButton';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';

type Props = {
  variant: SalesPhoneNumberButtonProps['variant'];
  eventName: string;
  label: SalesPhoneNumberButtonProps['label'];
};

const SalesPhoneNumber: FC<Props> = ({ eventName, variant, label }) => {
  const { push } = useRouter();
  const { authentication } = useAuthentication();

  const hasActiveSubscription = authentication?.authData?.hasActiveSubscription;

  const phoneNumber = hasActiveSubscription
    ? CSM_PHONE_NUMBER
    : SALES_PHONE_NUMBER;

  const handleClick = () => {
    handleSendEventGTM(eventName);
    push(`tel:${phoneNumber}`);
  };

  return (
    <PhoneNumberButton
      variant={variant}
      label={label}
      phoneNumber={phoneNumber}
      onClick={handleClick}
    />
  );
};

export default SalesPhoneNumber;

import { FC, PropsWithChildren } from 'react';

import useIsMobileApp from '@/shared/Hooks/useIsMobileApp';

const WebOnly: FC<PropsWithChildren> = ({ children }) => {
  const isMobileApp = useIsMobileApp();

  return !isMobileApp ? children : null;
};

export default WebOnly;

import { FC, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { UserType } from '@les-sherpas/sherpas-toolbox';

import Button from '@/designSystem/Button/Button';
import useGafamConnection from '@/Molecules/GafamButton/GafamConnection.hooks';
import { LoginSignUpVariant } from '@/Organisms/LoginSignUpModal/LoginSignUp.type';
import { Provider } from '@/types/provider';

import messages from './messages';

type Props = {
  userType?: UserType;
  provider: Provider;
  variant?: LoginSignUpVariant;
  onClick?: Function;
};

const GafamButton: FC<PropsWithChildren<Props>> = ({
  userType,
  provider,
  variant = 'login',
  onClick = () => {},
}) => {
  const { formatMessage } = useIntl();

  const { onClick: onGafamClick } = useGafamConnection({
    provider,
    userType,
    variant,
  });

  const handleClick = () => {
    onClick();
    onGafamClick();
  };

  return (
    <Button
      isFullWidth
      variant="secondary"
      onClick={handleClick}
      icon={provider === 'google' ? 'google' : 'facebook'}
      label={formatMessage(messages.button, { provider, variant })}
    />
  );
};

export default GafamButton;

import React, { useEffect, useState } from 'react';
import {
  StripeAccountSource,
  stripeAccountSource,
} from '@les-sherpas/sherpas-toolbox';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_SECRET = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;
const STRIPE_SHERPA_DOM_SECRET =
  process.env.NEXT_PUBLIC_STRIPE_SHERPA_DOM_PUBLIC_KEY;

const STRIPE_KEY: Record<StripeAccountSource, string> = {
  [stripeAccountSource.sherpasDom]: STRIPE_SHERPA_DOM_SECRET,
  [stripeAccountSource.sherpas]: STRIPE_SECRET,
};

const StripeElements = ({
  stripeEnvironment = stripeAccountSource.sherpas,
  children,
}: {
  stripeEnvironment?: StripeAccountSource;
  children: React.ReactNode;
}) => {
  const stripeKey = STRIPE_KEY[stripeEnvironment];
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(stripeKey)
  );
  const [elementsKey, setElementsKey] = useState(stripeKey);

  useEffect(() => {
    const newKey = STRIPE_KEY[stripeEnvironment];
    setElementsKey(newKey); // Met à jour la clé pour forcer le re-render
    setStripePromise(loadStripe(newKey)); // Charge Stripe avec la nouvelle clé
  }, [stripeEnvironment]);

  return (
    <Elements key={elementsKey} stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default StripeElements;

import { defineMessages } from 'react-intl';

export default defineMessages({
  passwordLabel: {
    id: 'ds.organisms.login.password.modal.body.label',
    defaultMessage: 'Mot de passe',
  },
  passwordPlaceholder: {
    id: 'ds.organisms.login.password.modal.body.placeholder',
    defaultMessage: 'Indiquez votre mot de passe',
  },
  emailLabel: {
    id: 'ds.organisms.login.email.modal.body.label',
    defaultMessage: 'Adresse email',
  },
  emailPlaceholder: {
    id: 'ds.organisms.login.email.modal.body.placeholder',
    defaultMessage: `Indiquez votre adresse email`,
  },
  forgetPassword: {
    id: 'ds.organisms.login.forget.password.modal.body',
    defaultMessage: `Mot de passe oublié ?`,
  },
  connect: {
    id: 'ds.organisms.login.modal.title',
    defaultMessage: `Connexion`,
  },
  globalError: {
    id: 'ds.organisms.login.validator.error',
    defaultMessage: `Le mail indiqué n'a pas de compte chez Les Sherpas, ou les identifiants sont invalides !`,
  },
  tooManyAttemptsError: {
    id: 'ds.organisms.login.validator.tooManyAttemptsError',
    defaultMessage:
      'Suite à un trop grand nombre de tentatives incorrectes, la connexion est temporairement bloquée. Veuillez réessayer dans 5 minutes.',
  },
});

import { useEffect, useState } from 'react';

export const useGoogleAuthenticationAvailability = () => {
  const [isGoogleGsiScriptLoaded, setIsGoogleGsiScriptLoaded] = useState(false);
  const [isFromFacebookApp, setIsFromFacebookApp] = useState(false);

  useEffect(() => {
    const checkIfIsFromFacebookApp = () => {
      const { userAgent } = navigator;
      const isFaceBookUserAgent =
        userAgent.includes('FBAN') || userAgent.includes('FBAV');

      setIsFromFacebookApp(isFaceBookUserAgent);
    };

    const googleScript = document.createElement('script');

    const checkLoadingOfGoogleGsiScript = () => {
      googleScript.src = 'https://accounts.google.com/gsi/client';
      googleScript.async = true;
      googleScript.defer = true;
      googleScript.onload = () => {
        setIsGoogleGsiScriptLoaded(true);
      };
      googleScript.onerror = () => {
        setIsGoogleGsiScriptLoaded(false);
      };

      document.body.appendChild(googleScript);
    };

    checkLoadingOfGoogleGsiScript();
    checkIfIsFromFacebookApp();

    return () => {
      document.body.removeChild(googleScript);
    };
  }, []);

  return {
    isGoogleAuthAvailable: isGoogleGsiScriptLoaded && !isFromFacebookApp,
  };
};

import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconInfo: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.33332C6.31811 3.33332 3.33334 6.31809 3.33334 9.99999C3.33334 13.6819 6.31811 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 9.99999C16.6667 6.31809 13.6819 3.33332 10 3.33332ZM1.66667 9.99999C1.66667 5.39762 5.39763 1.66666 10 1.66666C14.6024 1.66666 18.3333 5.39762 18.3333 9.99999C18.3333 14.6024 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 9.99999ZM9.16667 6.66666C9.16667 6.20642 9.53977 5.83332 10 5.83332H10.0083C10.4686 5.83332 10.8417 6.20642 10.8417 6.66666C10.8417 7.12689 10.4686 7.49999 10.0083 7.49999H10C9.53977 7.49999 9.16667 7.12689 9.16667 6.66666ZM8.33334 9.99999C8.33334 9.53975 8.70643 9.16666 9.16667 9.16666H10C10.4602 9.16666 10.8333 9.53975 10.8333 9.99999V12.5C11.2936 12.5 11.6667 12.8731 11.6667 13.3333C11.6667 13.7936 11.2936 14.1667 10.8333 14.1667H10C9.53977 14.1667 9.16667 13.7936 9.16667 13.3333V10.8333C8.70643 10.8333 8.33334 10.4602 8.33334 9.99999Z"
    />
  </BaseSvg>
);

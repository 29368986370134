export type ColorScheme = 'light' | 'dark';

type FoundationBase = 'default' | 'muted' | 'subtle' | 'darken' | 'emphasis';
type BorderFoundation = {
  [_key in Extract<
    FoundationBase,
    'darken' | 'default' | 'subtle' | 'muted'
  >]: string;
};
type BackgroundFoundation = {
  [_key in FoundationBase]: string;
};
type ForegroundFoundation = BackgroundFoundation;

type RoleBase = 'accent' | 'success' | 'attention' | 'danger' | 'brand';
type RoleIntention = 'neutral' | 'emphasis';
type BorderRole = {
  [_key in RoleBase]: {
    [_key in RoleIntention]: string;
  };
};
type BackgroundRole = BorderRole;
type ForegroundRole = {
  [_key in RoleBase]: string;
};

export type ThemeColor = {
  border: BorderFoundation & BorderRole;
  background: BackgroundFoundation & BackgroundRole;
  foreground: ForegroundFoundation & ForegroundRole;
};
type AppTheme = {
  [_key in ColorScheme]: ThemeColor;
};

const lightTheme: ThemeColor = {
  border: {
    muted: '#FFFFFF',
    subtle: '#F4F4F5',
    default: '#E4E4E7',
    darken: '#D4D4D8',
    accent: {
      neutral: '#3B82F6',
      emphasis: '#1D4ED8',
    },
    success: {
      neutral: '#22C55E',
      emphasis: '#15803D',
    },
    attention: {
      neutral: '#F59E0B',
      emphasis: '#B45309',
    },
    danger: {
      neutral: '#EF4444',
      emphasis: '#B91C1C',
    },
    brand: {
      neutral: '#50C686',
      emphasis: '#3E9A67',
    },
  },
  background: {
    default: '#FFFFFF',
    muted: '#FAFAFA',
    subtle: '#F4F4F5',
    darken: '#E4E4E7',
    emphasis: '#18181B',
    accent: {
      neutral: '#EFF6FF',
      emphasis: '#1D4ED8',
    },
    success: {
      neutral: '#F0FDF4',
      emphasis: '#15803D',
    },
    attention: {
      neutral: '#FFFBEB',
      emphasis: '#F59E0B',
    },
    danger: {
      neutral: '#FEF2F2',
      emphasis: '#B91C1C',
    },
    brand: {
      neutral: '#F3FBF7',
      emphasis: '#3E9A67',
    },
  },
  foreground: {
    default: '#27272A',
    muted: '#71717A',
    subtle: '#A1A1AA',
    darken: '#18181B',
    emphasis: '#FFFFFF',
    accent: '#1D4ED8',
    success: '#15803D',
    attention: '#B45309',
    danger: '#B91C1C',
    brand: '#3E9A67',
  },
};

// Only light theme is currently available, but everything is set up for a dark theme
export const themeColor: AppTheme = {
  light: lightTheme,
  dark: lightTheme,
};

import { useRouter } from 'next/router';

function useIsMobileApp() {
  const { query } = useRouter();

  const isMobileApp = query.isMobileApp === 'true';

  return isMobileApp;
}

export default useIsMobileApp;

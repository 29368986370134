import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconFacebook: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      d="M10.0001 18.3347C14.6025 18.3347 18.3334 14.6038 18.3334 10.0014C18.3334 5.39904 14.6025 1.66809 10.0001 1.66809C5.39771 1.66809 1.66675 5.39904 1.66675 10.0014C1.66675 14.6038 5.39771 18.3347 10.0001 18.3347Z"
      fill="#1977F3"
    />
    <path
      d="M13.244 12.4106L13.6131 10.0012H11.3022V8.43786C11.3022 7.77926 11.6244 7.13589 12.6604 7.13589H13.7116V5.08507C13.7116 5.08507 12.7577 4.92218 11.8459 4.92218C9.94278 4.92218 8.69823 6.07532 8.69823 8.16481V10.0012H6.58179V12.4106H8.69823V18.2337C9.12245 18.3005 9.55722 18.3345 10.0002 18.3345C10.4432 18.3345 10.8779 18.2993 11.3022 18.2337V12.4106H13.244Z"
      fill="white"
    />
  </BaseSvg>
);

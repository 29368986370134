import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconLive: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <rect x="6" y="6" width="8" height="8" rx="4" />
  </BaseSvg>
);

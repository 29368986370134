import { UserType } from '@les-sherpas/sherpas-toolbox';
import posthog from 'posthog-js';

export const identifyUser = ({
  email,
  userType,
  hasActiveSubscription,
}: {
  email: string;
  userType?: UserType;
  hasActiveSubscription?: boolean;
}) => {
  posthog.identify(email, { email, userType, hasActiveSubscription });
};

export const resetUser = () => {
  posthog.reset();
};

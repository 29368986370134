import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconYoutube: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      d="M17.9654 5.9945C17.8698 5.6412 17.6833 5.31912 17.4245 5.06031C17.1657 4.80151 16.8436 4.61502 16.4903 4.51942C15.1969 4.16669 9.99141 4.16669 9.99141 4.16669C9.99141 4.16669 4.78589 4.17738 3.49252 4.53011C3.13923 4.62571 2.81714 4.8122 2.55834 5.071C2.29954 5.3298 2.11305 5.65189 2.01745 6.00519C1.62623 8.30331 1.47445 11.805 2.02814 14.0112C2.12374 14.3645 2.31023 14.6866 2.56903 14.9454C2.82783 15.2042 3.14992 15.3907 3.50321 15.4863C4.79658 15.839 10.0021 15.839 10.0021 15.839C10.0021 15.839 15.2076 15.839 16.501 15.4863C16.8543 15.3907 17.1764 15.2042 17.4352 14.9454C17.694 14.6866 17.8804 14.3645 17.9761 14.0112C18.3886 11.7099 18.5158 8.21032 17.9654 5.9945Z"
      fill="#FF0000"
    />
    <path
      d="M8.33447 12.5041L12.6528 10.0029L8.33447 7.50171V12.5041Z"
      fill="#FFFFFF"
    />
  </BaseSvg>
);

import { useFeatureFlagEnabled } from 'posthog-js/react';

const useFeatureFlagSherpaDom = () => {
  const isFeatureFlagSherpaDomEnabled = useFeatureFlagEnabled('ffSherpaDom');

  return {
    isFeatureFlagSherpaDomEnabled,
  };
};

export default useFeatureFlagSherpaDom;

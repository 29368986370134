import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconStar: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path d="M9.04893 3.92704C9.34828 3.00573 10.6517 3.00573 10.951 3.92704L12.0206 7.21883C12.1545 7.63086 12.5384 7.90982 12.9717 7.90982H16.4329C17.4016 7.90982 17.8044 9.14943 17.0207 9.71883L14.2205 11.7533C13.87 12.0079 13.7233 12.4593 13.8572 12.8713L14.9268 16.1631C15.2261 17.0844 14.1717 17.8505 13.3879 17.2811L10.5878 15.2467C10.2373 14.9921 9.76269 14.9921 9.4122 15.2467L6.61203 17.2811C5.82832 17.8505 4.77384 17.0844 5.07319 16.1631L6.14276 12.8713C6.27663 12.4593 6.12997 12.0079 5.77949 11.7533L2.97932 9.71883C2.1956 9.14943 2.59838 7.90982 3.5671 7.90982H7.0283C7.46153 7.90982 7.84548 7.63086 7.97936 7.21883L9.04893 3.92704Z" />
  </BaseSvg>
);

import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconCalendarOutlined: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66675 1.66666C7.12698 1.66666 7.50008 2.03976 7.50008 2.5V3.33333H12.5001V2.5C12.5001 2.03976 12.8732 1.66666 13.3334 1.66666C13.7937 1.66666 14.1667 2.03976 14.1667 2.5V3.33333H15.8334C17.2141 3.33333 18.3334 4.45262 18.3334 5.83333V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V5.83333C1.66675 4.45262 2.78604 3.33333 4.16675 3.33333H5.83341V2.5C5.83341 2.03976 6.20651 1.66666 6.66675 1.66666ZM5.83341 5H4.16675C3.70651 5 3.33341 5.37309 3.33341 5.83333V15.8333C3.33341 16.2936 3.70651 16.6667 4.16675 16.6667H15.8334C16.2936 16.6667 16.6667 16.2936 16.6667 15.8333V5.83333C16.6667 5.37309 16.2936 5 15.8334 5H14.1667V5.83333C14.1667 6.29357 13.7937 6.66666 13.3334 6.66666C12.8732 6.66666 12.5001 6.29357 12.5001 5.83333V5H7.50008V5.83333C7.50008 6.29357 7.12698 6.66666 6.66675 6.66666C6.20651 6.66666 5.83341 6.29357 5.83341 5.83333V5ZM5.00008 9.16666C5.00008 8.70643 5.37318 8.33333 5.83341 8.33333H14.1667C14.627 8.33333 15.0001 8.70643 15.0001 9.16666C15.0001 9.6269 14.627 10 14.1667 10H5.83341C5.37318 10 5.00008 9.6269 5.00008 9.16666Z"
    />
  </BaseSvg>
);

import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  StripeAccountSource,
  stripeAccountSource,
} from '@les-sherpas/sherpas-toolbox';

import StripeElements from '@/components/Stripe/StripeElements';

type StripeContextValue = {
  stripeEnvironment: StripeAccountSource;
  setStripeEnvironment: Dispatch<SetStateAction<StripeAccountSource>>;
};

export const StripeContext = createContext<StripeContextValue>(
  {} as StripeContextValue
);

type Props = {
  initialStripeAccountSource?: StripeAccountSource;
};

export const StripeContextProvider: FC<PropsWithChildren<Props>> = ({
  children,
  initialStripeAccountSource,
}) => {
  const [stripeEnvironment, setStripeEnvironment] =
    useState<StripeAccountSource>(
      initialStripeAccountSource ?? stripeAccountSource.sherpas
    );

  const contextValue = useMemo(
    () => ({
      stripeEnvironment,
      setStripeEnvironment,
    }),
    [stripeEnvironment, setStripeEnvironment]
  );

  return (
    <StripeContext.Provider value={contextValue}>
      <StripeElements stripeEnvironment={stripeEnvironment}>
        {children}
      </StripeElements>
    </StripeContext.Provider>
  );
};

export const useStripeContext = () => {
  const context = useContext(StripeContext);
  if (!context) {
    throw new Error(
      "le context ne peut pas être utilisé si le provider StripeElementsProvider n'a pas été setté"
    );
  }
  return context;
};

import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconExclamationOutlined: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83507 2.91666C8.79732 1.25 11.2029 1.25 12.1652 2.91666L17.9387 12.9167C18.9009 14.5833 17.6981 16.6667 15.7736 16.6667H4.22663C2.30213 16.6667 1.09932 14.5833 2.06157 12.9167L7.83507 2.91666ZM10.7218 3.75C10.4011 3.19444 9.5992 3.19444 9.27844 3.75L3.50494 13.75C3.18419 14.3056 3.58513 15 4.22663 15H15.7736C16.4151 15 16.8161 14.3056 16.4953 13.75L10.7218 3.75ZM10.0001 6.66666C10.4604 6.66666 10.8335 7.03976 10.8335 7.5V9.16666C10.8335 9.6269 10.4604 10 10.0001 10C9.5399 10 9.1668 9.6269 9.1668 9.16666V7.5C9.1668 7.03976 9.5399 6.66666 10.0001 6.66666ZM9.1668 12.5C9.1668 12.0398 9.5399 11.6667 10.0001 11.6667H10.0085C10.4687 11.6667 10.8418 12.0398 10.8418 12.5C10.8418 12.9602 10.4687 13.3333 10.0085 13.3333H10.0001C9.5399 13.3333 9.1668 12.9602 9.1668 12.5Z"
    />
  </BaseSvg>
);

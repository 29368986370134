import { UserType } from '@les-sherpas/sherpas-toolbox';
import server from 'config';

import { ONBOARDING_FUNNEL_SETTINGS } from '@/data/navbar/navButtons';
import useLocalStorage from '@/hooks/useLocalStorage';
import { LoginSignUpVariant } from '@/Organisms/LoginSignUpModal/LoginSignUp.type';
import useNavigation from '@/shared/Hooks/useNavigation';
import { OnboardingSettings } from '@/types/onboardingInfo';
import { Provider } from '@/types/provider';

type Props = {
  provider: Provider;
  userType?: UserType;
  variant: LoginSignUpVariant;
};

const useGafamConnection = ({ provider, userType, variant }: Props) => {
  const { pushTo, urls } = useNavigation();

  const { getItem, setItem } = useLocalStorage<OnboardingSettings>(
    ONBOARDING_FUNNEL_SETTINGS
  );

  const isStudent = userType === UserType.Student;

  const onClick = () => {
    const items = getItem();

    if (variant === 'signup') {
      setItem({ ...items, registerMode: provider });
      if (!userType) {
        pushTo(urls.funnelSignUpUserTypeSelection());
        return;
      }

      /** create account with provider */
      const redirect = isStudent
        ? urls.funnelSignUpStudent()
        : urls.sherpaSignUp({ step: 3 });

      const redirectUrl = new URL(
        `${server}auth/${userType}/${items?.registerMode}`
      );
      redirectUrl.searchParams.set('canRegister', 'true');
      redirectUrl.searchParams.set(
        'redirect',
        `${window.location.origin}${redirect}`
      );
      redirectUrl.searchParams.set('loginRedirectUrl', items.drawerExitUrl);
      window.location.assign(redirectUrl);
    }

    if (variant === 'login') {
      const redirectUrl = new URL(`${server}auth/${provider}`);

      redirectUrl.searchParams.set(
        'redirectError',
        `${window.location.pathname}`
      );

      if (items.origin === 'announce') {
        redirectUrl.searchParams.set('redirect', items.drawerExitUrl);
      }

      if (items.origin === 'announceNavbar') {
        redirectUrl.searchParams.set(
          'redirect',
          `${window.location.origin}/${items.drawerExitUrl}`
        );
      }

      if (items.origin === 'search') {
        redirectUrl.searchParams.set('redirect', items.drawerExitUrl);
      }

      window.location.assign(redirectUrl);
    }
  };

  return {
    onClick,
  };
};

export default useGafamConnection;

import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  shouldDisplayLoginForm?: boolean;
};

export default makeStyles<Props>()(
  ({ palette }, { shouldDisplayLoginForm }) => ({
    img: {
      backgroundColor: palette.neutral.light,
      borderRadius: rem(100),
      display: 'flex',
      height: rem(100),
      width: rem(100),
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleWithCloseButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    icon: {
      color: palette.neutral[200],
      position: shouldDisplayLoginForm ? 'relative' : 'absolute',
      right: !shouldDisplayLoginForm && 0,
      paddingTop: rem(12),
      borderRadius: rem(8),
    },
    titleWithCloseIcon: {
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: '100%',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginBottom: rem(24),
    },
    closeIcon: {
      textAlign: 'right',
    },
  })
);

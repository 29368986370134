import { FC, PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';
import useNavigation, { urls } from '@/shared/Hooks/useNavigation';

import useStyles from './styles';

type Link = {
  label: string | ReactElement;
  url?: string;
  name: string;
  onClick?: () => void;
};

type Props = {
  title: string | ReactElement;
  links: Link[];
};

const NO_LINKS_URL = [
  'https://les-sherpas.zendesk.com/hc/fr/sections/360001733194-Paiements',
  'https://les-sherpas.zendesk.com/hc/fr',
  urls.legalNotice({ hash: 'cgu' }),
  '/',
  'https://les-sherpas.zendesk.com/hc/fr/articles/360008427634-Quels-sont-les-tarifs-de-vos-professeurs-',
];

const SectionFooter: FC<PropsWithChildren<Props>> = ({ title, links }) => {
  const { classes } = useStyles();
  const { pushToUnsafe } = useNavigation();

  return (
    <div className={classes.sectionFooter}>
      <Typography variant="bodyLgBold" component="span">
        {title}
      </Typography>
      <ul className={classes.sectionLink}>
        {links.map(({ label, url, name, onClick }, index) =>
          NO_LINKS_URL.some((link) => link === url) || name === 'CGU' ? (
            <li
              key={`${label}_${index}`}
              className={classes.linkContainerWithLi}
            >
              <button
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  if (onClick) onClick();
                  else pushToUnsafe(url);
                }}
                className={classes.noCssButton}
              >
                <Typography variant="bodyLg" component="span">
                  {label}
                </Typography>
              </button>
            </li>
          ) : (
            <li
              key={`${label}_${index}`}
              className={classes.linkContainerWithLi}
            >
              <NextLinkWithNoCss
                href={url}
                onClick={(event) => {
                  window.dataLayer.push({
                    menu: name,
                    event: 'clic_footer',
                  });
                  if (onClick) {
                    event.preventDefault();
                    onClick();
                  }
                }}
              >
                <Typography variant="bodyLg" component="span">
                  {label}
                </Typography>
              </NextLinkWithNoCss>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default SectionFooter;

import { ChangeEvent, FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserType } from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';

import { usePreFetchAuthentication } from '@/components/Authentication/useAuthentication';
import { login } from '@/components/OnBoarding/utils';
import Button from '@/designSystem/Button/Button';
import { errorColor } from '@/designSystem/theme';
import { useForm } from '@/hooks/useForm';
import Form from '@/Molecules/Form/Form';
import Helper from '@/Molecules/Helper/Helper';
import Input from '@/Molecules/Input/Input';
import ModalLoader from '@/Molecules/Loader/ModalLoader/ModalLoader';
import fieldsConfig from '@/Organisms/LoginSignUpModal/LoginEmailModalBody/loginEmailModalBodyFields';
import useNavigation from '@/shared/Hooks/useNavigation';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';

import messages from './messages';

import useStyles from './styles';

type EmailBodyDto = {
  username: string;
  password: string;
};

type Props = {
  closeModal: () => void;
  handleRedirection: (_userType: UserType) => void;
};

const LoginEmailModalBody: FC<Props> = ({ closeModal, handleRedirection }) => {
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const { prefetchAuthentication } = usePreFetchAuthentication();
  const { pushTo, urls } = useNavigation();

  const { values, handleInputChange, handleInputBlur, isFormValid, errors } =
    useForm<{
      username: string;
      password: string;
    }>({
      fields: fieldsConfig(formatMessage),
    });

  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    setIsLoading(true);
    setErrorMessage(undefined);
    try {
      e.preventDefault();
      const user: EmailBodyDto = {
        username: values.username,
        password: values.password,
      };

      const result = await login(user);
      if (result.success) {
        handleSendEventGTM('sign_in');
        await prefetchAuthentication();
        handleRedirection(result.user.userType);
        closeModal();
      } else {
        setErrorMessage(formatMessage(messages.globalError));

        if (result.hasBeenCaught && result.hasBeenRejectedByWaf) {
          setErrorMessage(formatMessage(messages.tooManyAttemptsError));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    pushTo(urls.resetPassword());
    closeModal();
  };

  return (
    <>
      <form className={classes.formContainer} onSubmit={handleSubmit}>
        <div>
          <Form
            className={classes.fullWidth}
            label={formatMessage(messages.emailLabel)}
          >
            <Input
              type="text"
              name="username"
              value={values.username}
              placeholder={formatMessage(messages.emailPlaceholder)}
              onChangeForUseForm={handleInputChange}
              onBlurForUseForm={handleInputBlur}
            />
            {!!errors?.username?.length && (
              <Helper
                identifier=""
                text={errors.username[0]}
                color={errorColor}
              />
            )}
          </Form>
        </div>
        <div>
          <Form
            className={classes.fullWidth}
            label={formatMessage(messages.passwordLabel)}
          >
            <Input
              type="password"
              name="password"
              value={values.password}
              placeholder={formatMessage(messages.passwordPlaceholder)}
              onChangeForUseForm={handleInputChange}
              onBlurForUseForm={handleInputBlur}
            />
            {!!errors?.password?.length && (
              <Helper
                identifier=""
                text={errors.password[0]}
                color={errorColor}
              />
            )}
          </Form>
          <button
            type="button"
            className={classes.button}
            onClick={handleForgotPassword}
          >
            <Typography
              variant="bodySm"
              component="p"
              className={classes.connect}
            >
              <FormattedMessage {...messages.forgetPassword} />
            </Typography>
          </button>
        </div>
        {errorMessage && <Helper text={errorMessage} color={errorColor} />}
        <Button
          type="submit"
          variant="primary"
          isDisabled={!isFormValid || isLoading}
          label={formatMessage(messages.connect)}
          isFullWidth
        />
      </form>

      {isLoading ? <ModalLoader /> : null}
    </>
  );
};

export default LoginEmailModalBody;

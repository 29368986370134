import { useMemo, useState } from 'react';

type Validator = {
  validator: (_value: unknown) => boolean;
  message: string;
};

type Field<T> = {
  name: string;
  validators: Validator[];
  defaultValue?: T;
  isOptional?: boolean;
};

type Props<T> = {
  fields: Field<T[keyof T]>[];
};

export type UseFormInputChange<T> = ({
  // eslint-disable-next-line no-unused-vars
  name,
  // eslint-disable-next-line no-unused-vars
  value,
}: {
  name: string;
  value: T;
}) => void;

export type UseFormInputBlur<T> = UseFormInputChange<T>;

type UseFormReturn<T> = {
  values: T;
  isFormValid: boolean;
  errors: UseFormErrors;
  handleInputChange: UseFormInputChange<T[keyof T]>;
  handleInputBlur: UseFormInputBlur<T[keyof T]>;
  handleFormSubmit: () => void;
  validate: () => boolean;
};

export type ValidationStatus =
  | {
      isFormValid: true;
      errors: undefined;
    }
  | {
      isFormValid: false;
      errors: UseFormErrors;
    };

export type UseFormErrors = Record<string, string[]>;

const getFieldErrorMessages = <T extends unknown>(
  field: Field<T[keyof T]>,
  value: T[keyof T]
): string[] =>
  field.validators.flatMap(({ message, validator }) => {
    const isFieldValid = validator(value);
    return isFieldValid ? [] : [message];
  });

const isEmptyValue = <T extends unknown>(value: T[keyof T]): boolean =>
  value === undefined || value === null || value === '';

const shouldIgnoreValidation = <T extends unknown>(
  field: Field<T[keyof T]>,
  value: T[keyof T]
): boolean => isEmptyValue(value) && field.isOptional;

export function useForm<T>({ fields }: Props<T>): UseFormReturn<T> {
  const [hasFormChanged, setHasFormChanged] = useState(
    fields.some((field) => !isEmptyValue(field.defaultValue))
  );
  const [blurredFields, setBlurredFields] = useState<string[]>(
    fields
      .filter((field) => !isEmptyValue(field.defaultValue))
      .map(({ name }) => name)
  );
  const [values, setValues] = useState<T>(
    fields
      .filter((field) => 'defaultValue' in field)
      .reduce((prev, next) => {
        // eslint-disable-next-line no-param-reassign
        prev[next.name] = next.defaultValue;
        return prev;
      }, {} as T)
  );
  const [errors, setErrors] = useState<UseFormErrors>(
    fields
      .filter((field) => !isEmptyValue(field.defaultValue))
      .reduce((acc: UseFormErrors, field: Field<T[keyof T]>) => {
        const errorMessages = getFieldErrorMessages(field, field.defaultValue);
        return { ...acc, [field.name]: errorMessages };
      }, {})
  );
  const [isValidOnDemand, setIsValidOnDemand] = useState(false);

  const validateField = (field: Field<T[keyof T]>, value: T[keyof T]) => {
    const errorMessages = shouldIgnoreValidation(field, value)
      ? []
      : getFieldErrorMessages(field, value);
    setErrors((prev) => ({ ...prev, [field.name]: errorMessages }));
    return errorMessages;
  };

  const validateInput = (name: string, value: T[keyof T]) => {
    const field = fields.find((field) => field.name === name);
    validateField(field, value);
  };

  const validate = () => {
    const validations = fields?.flatMap((field) =>
      validateField(field, values[field.name])
    );
    const isValid = validations.length === 0;
    setIsValidOnDemand(isValid);
    return isValid;
  };

  const isFormValid = useMemo(
    () =>
      isValidOnDemand ||
      (hasFormChanged &&
        !fields.some((field) =>
          field.validators.some(({ validator }) => {
            const value = values[field.name];
            return !shouldIgnoreValidation(field, value) && !validator(value);
          })
        )),
    [fields, hasFormChanged, values, isValidOnDemand]
  );

  const handleInputBlur: UseFormInputBlur<T[keyof T]> = ({ name, value }) => {
    if (!blurredFields.includes(name)) {
      setBlurredFields((prev) => [...prev, name]);
      validateInput(name, value);
    }
  };

  const handleInputChange: UseFormInputChange<T[keyof T]> = ({
    name,
    value,
  }) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setHasFormChanged(true);
    if (blurredFields.includes(name)) {
      validateInput(name, value);
    }
  };

  const handleSubmit = async () => {
    setHasFormChanged(false);
  };

  return {
    values,
    isFormValid,
    errors,
    handleInputChange,
    handleInputBlur,
    handleFormSubmit: handleSubmit,
    validate,
  };
}

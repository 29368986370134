import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconDanger: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.33333C6.31811 3.33333 3.33334 6.3181 3.33334 10C3.33334 13.6819 6.31811 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333ZM1.66667 10C1.66667 5.39762 5.39763 1.66666 10 1.66666C14.6024 1.66666 18.3333 5.39762 18.3333 10C18.3333 14.6024 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 10ZM10 5.83333C10.4602 5.83333 10.8333 6.20643 10.8333 6.66666V10C10.8333 10.4602 10.4602 10.8333 10 10.8333C9.53977 10.8333 9.16667 10.4602 9.16667 10V6.66666C9.16667 6.20643 9.53977 5.83333 10 5.83333ZM9.16667 13.3333C9.16667 12.8731 9.53977 12.5 10 12.5H10.0083C10.4686 12.5 10.8417 12.8731 10.8417 13.3333C10.8417 13.7936 10.4686 14.1667 10.0083 14.1667H10C9.53977 14.1667 9.16667 13.7936 9.16667 13.3333Z"
    />
  </BaseSvg>
);

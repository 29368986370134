import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconChatAlt2Outlined: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66675 5C1.66675 3.61929 2.78604 2.5 4.16675 2.5H12.5001C13.8808 2.5 15.0001 3.61929 15.0001 5V5.83333H15.8334C17.2141 5.83333 18.3334 6.95262 18.3334 8.33333V13.3333C18.3334 14.714 17.2141 15.8333 15.8334 15.8333H15.0001V18.3333C15.0001 18.6704 14.797 18.9743 14.4856 19.1032C14.1743 19.2322 13.8158 19.1609 13.5775 18.9226L10.4882 15.8333H7.50008C7.11443 15.8333 6.74877 15.7457 6.42239 15.5895C6.18406 15.8276 5.82579 15.8988 5.51451 15.7699C5.20312 15.6409 5.00008 15.3371 5.00008 15V12.5H4.16675C2.78604 12.5 1.66675 11.3807 1.66675 10V5ZM7.84526 14.1667H10.8334C11.0544 14.1667 11.2664 14.2545 11.4227 14.4107L13.3334 16.3215V15C13.3334 14.5398 13.7065 14.1667 14.1667 14.1667H15.8334C16.2936 14.1667 16.6667 13.7936 16.6667 13.3333V8.33333C16.6667 7.8731 16.2936 7.5 15.8334 7.5H15.0001V10C15.0001 11.3807 13.8808 12.5 12.5001 12.5H9.51192L7.84526 14.1667ZM13.3334 5C13.3334 4.53976 12.9603 4.16667 12.5001 4.16667H4.16675C3.70651 4.16667 3.33341 4.53976 3.33341 5V10C3.33341 10.4602 3.70651 10.8333 4.16675 10.8333H5.83341C6.29365 10.8333 6.66675 11.2064 6.66675 11.6667V12.9882L8.57749 11.0774C8.73377 10.9211 8.94573 10.8333 9.16675 10.8333H12.5001C12.9603 10.8333 13.3334 10.4602 13.3334 10V5Z"
    />
  </BaseSvg>
);

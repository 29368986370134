import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconMasterCard: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      d="M18.3333 3H1.66667C0.746192 3 0 3.7835 0 4.75V15.25C0 16.2165 0.746192 17 1.66667 17H18.3333C19.2538 17 20 16.2165 20 15.25V4.75C20 3.7835 19.2538 3 18.3333 3Z"
      fill="#252525"
    />
    <path
      d="M7.49992 14.375C9.80111 14.375 11.6666 12.4162 11.6666 10C11.6666 7.58375 9.80111 5.625 7.49992 5.625C5.19873 5.625 3.33325 7.58375 3.33325 10C3.33325 12.4162 5.19873 14.375 7.49992 14.375Z"
      fill="#EB001B"
    />
    <path
      d="M12.4999 14.375C14.8011 14.375 16.6666 12.4162 16.6666 10C16.6666 7.58375 14.8011 5.625 12.4999 5.625C10.1987 5.625 8.33325 7.58375 8.33325 10C8.33325 12.4162 10.1987 14.375 12.4999 14.375Z"
      fill="#F79E1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99992 6.49963C11.012 7.29781 11.6666 8.56861 11.6666 9.99995C11.6666 11.4313 11.012 12.7021 9.99992 13.5003C8.98788 12.7021 8.33325 11.4313 8.33325 9.99995C8.33325 8.56861 8.98788 7.29781 9.99992 6.49963V6.49963Z"
      fill="#FF5F00"
    />
  </BaseSvg>
);

import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/Internal/BaseSvg';

export const IconSparklesOutlined: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16675 1.66666C4.62698 1.66666 5.00008 2.03975 5.00008 2.49999V3.33332H5.83341C6.29365 3.33332 6.66675 3.70642 6.66675 4.16666C6.66675 4.62689 6.29365 4.99999 5.83341 4.99999H5.00008V5.83332C5.00008 6.29356 4.62698 6.66666 4.16675 6.66666C3.70651 6.66666 3.33341 6.29356 3.33341 5.83332V4.99999H2.50008C2.03984 4.99999 1.66675 4.62689 1.66675 4.16666C1.66675 3.70642 2.03984 3.33332 2.50008 3.33332H3.33341V2.49999C3.33341 2.03975 3.70651 1.66666 4.16675 1.66666ZM10.8334 1.66666C11.1921 1.66666 11.5106 1.89618 11.624 2.23647L13.403 7.57359L17.7927 9.21971C18.1179 9.34168 18.3334 9.65262 18.3334 9.99999C18.3334 10.3474 18.1179 10.6583 17.7927 10.7803L13.403 12.4264L11.624 17.7635C11.5106 18.1038 11.1921 18.3333 10.8334 18.3333C10.4747 18.3333 10.1563 18.1038 10.0428 17.7635L8.2638 12.4264L3.87414 10.7803C3.54889 10.6583 3.33341 10.3474 3.33341 9.99999C3.33341 9.65262 3.54889 9.34168 3.87414 9.21971L8.2638 7.57359L10.0428 2.23647C10.1563 1.89618 10.4747 1.66666 10.8334 1.66666ZM10.8334 5.13522L9.71922 8.4778C9.6397 8.71636 9.45671 8.90625 9.22125 8.99455L6.54008 9.99999L9.22125 11.0054C9.45671 11.0937 9.6397 11.2836 9.71922 11.5222L10.8334 14.8648L11.9476 11.5222C12.0271 11.2836 12.2101 11.0937 12.4456 11.0054L15.1267 9.99999L12.4456 8.99455C12.2101 8.90625 12.0271 8.71636 11.9476 8.4778L10.8334 5.13522ZM5.00008 13.3333C5.46032 13.3333 5.83341 13.7064 5.83341 14.1667V15H6.66675C7.12698 15 7.50008 15.3731 7.50008 15.8333C7.50008 16.2936 7.12698 16.6667 6.66675 16.6667H5.83341V17.5C5.83341 17.9602 5.46032 18.3333 5.00008 18.3333C4.53984 18.3333 4.16675 17.9602 4.16675 17.5V16.6667H3.33341C2.87318 16.6667 2.50008 16.2936 2.50008 15.8333C2.50008 15.3731 2.87318 15 3.33341 15H4.16675V14.1667C4.16675 13.7064 4.53984 13.3333 5.00008 13.3333Z"
    />
  </BaseSvg>
);

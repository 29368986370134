import { FC } from 'react';

import { useStripeSherpaDomSetup } from './useStripeSherpaDomSetup';

type Props = {};

const StripeSherpaDomTeacherMigration: FC<Props> = () => {
  useStripeSherpaDomSetup();

  return null;
};

export default StripeSherpaDomTeacherMigration;

import { useEffect } from 'react';
import {
  DocType,
  Gender,
  PaymentSherpasDomApi,
  UserType,
} from '@les-sherpas/sherpas-toolbox';
import { useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import server from 'config';

import useAuthentication from '@/components/Authentication/useAuthentication';

export async function useStripeSherpaDomSetup() {
  const { authentication } = useAuthentication();
  const stripe = useStripe();

  useEffect(() => {
    async function createSherpaDomAcct() {
      // 4. on rassemble toutes les informations deja connues de l'utilisateur et on cree le token de compte connect
      const {
        data: { verificationDocuments },
      } = await axios.get<
        PaymentSherpasDomApi['private']['migrateVerificationDocuments']['response']
      >(`${server}payment-sherpas-dom/private/migrate-verification-documents`, {
        withCredentials: true,
      });

      const verification = {
        document: { front: undefined, back: undefined },
        additional_document: { front: undefined, back: undefined },
      };
      // eslint-disable-next-line no-restricted-syntax
      for (const document of verificationDocuments) {
        const documentType =
          document.type === DocType.Identity
            ? 'document'
            : 'additional_document';
        if (!verification[documentType].front) {
          verification[documentType].front = document.stripeFileId;
        } else {
          verification[documentType].back = document.stripeFileId;
        }
      }

      const birthday = new Date(authentication.teacherInfo.birthday);
      const { token } = await stripe.createToken('account', {
        business_type: 'individual',
        individual: {
          first_name: authentication.authData.firstName,
          last_name: authentication.authData.lastName,
          email: authentication.authData.email,
          dob: {
            day: birthday.getDate(),
            month: birthday.getMonth() + 1,
            year: birthday.getFullYear(),
          },
          address: {
            line1: authentication.authData.address?.formattedAddress,
            city: authentication.authData.address?.city,
            postal_code: authentication.authData.address?.zipCode,
            country: 'FR',
          },
          phone: authentication.authData.phoneNumber,
          gender:
            authentication.authData.gender === Gender.Other
              ? Gender.Female
              : authentication.authData.gender,
          verification,
        },
        tos_shown_and_accepted: true,
      });

      try {
        // 5. on utilise le token precedemment cree pour enregistrer la creation du compte connect SherpaDom sur Stripe et dans notre DB
        await axios.post(
          `${server}payment-sherpas-dom/private/create-account`,
          { tokenId: token.id },
          { withCredentials: true }
        );
      } catch (error) {
        // ? Silent fail. This is due to a creation spam (causing some unicity constraint to fail)
        // ? that can happens when the user is navigating and thus changing page before the end of this async process
      }
    }

    // 1. on s'assure que l'utilisateur est connecte avec un compte teacher
    if (authentication?.authData?.userType !== UserType.Teacher) {
      return;
    }

    // 2. on s'assure que l'utilisateur connecte n'a pas encore de compte Stripe sur SherpaDom
    if (authentication.teacherInfo.hasSherpaDomStripeAcct) {
      return;
    }

    const { birthday } = authentication.teacherInfo;
    const { firstName, lastName, email, phoneNumber } = authentication.authData;
    // 3. on s'assure de bien avoir toutes les informations nécessaire pour cet utilisateur
    const hasRequiredInformationsForSetup =
      !!firstName && !!lastName && !!email && !!birthday && !!phoneNumber;
    if (!hasRequiredInformationsForSetup) {
      return;
    }

    createSherpaDomAcct();
  }, [authentication, stripe]);
}

import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import dynamic from 'next/dynamic';

import { LoginSignUpModalProps } from '@/Organisms/LoginSignUpModal/LoginSignUpModal';

const LoginSignUpModal = dynamic(
  () => import('@/Organisms/LoginSignUpModal/LoginSignUpModal'),
  {
    ssr: false,
  }
);

type Props = LoginSignUpModalProps & {
  // eslint-disable-next-line no-unused-vars
  renderItem: ({ toggle }: { toggle: () => void }) => ReactElement;
};

const LoginSignUpModalWrapper: FC<PropsWithChildren<Props>> = ({
  origin,
  initialVariant,
  drawerExitUrl,
  teacher,
  hasLoginError,
  setHasLoginError,
  renderItem,
}) => {
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const toggle = useCallback(() => setIsOpenLoginModal((prev) => !prev), []);

  return (
    <>
      {renderItem({ toggle })}
      {isOpenLoginModal && (
        <LoginSignUpModal
          isOpen={isOpenLoginModal}
          onClose={() => setIsOpenLoginModal(false)}
          origin={origin}
          initialVariant={initialVariant}
          drawerExitUrl={drawerExitUrl}
          teacher={teacher}
          hasLoginError={hasLoginError}
          setHasLoginError={setHasLoginError}
        />
      )}
    </>
  );
};

export default LoginSignUpModalWrapper;

import { FC, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dynamic from 'next/dynamic';
import { useStyles } from 'tss-react';

import useAuthentication from '@/components/Authentication/useAuthentication';
import HeaderContainer from '@/components/HeaderContainer/HeaderContainer';
import { inter, outfit } from '@/designSystem/theme';
import Footer from '@/Molecules/Footer/Footer';
import GlobalSignupModal from '@/shared/GlobalSignupModal/GlobalSignupModal';
import Navbar from '@/shared/Navbar/Navbar';
import WebOnly from '@/shared/WebOnly/WebOnly';

import messages from './messages';

type Props = {
  isFooterDisplayed?: boolean;
  isNavbarDisplayed?: boolean;
  isTvBannerDisplayed?: boolean;
};

const LazyLoad = {
  NotificationMessage: dynamic(
    () => import('@/Organisms/Notification/NotificationMessage')
  ),
  TVBanner: dynamic(() => import('@/components/TVBanner/TVBanner')),
};

const Layout: FC<PropsWithChildren<Props>> = ({
  isFooterDisplayed = true,
  isNavbarDisplayed = true,
  isTvBannerDisplayed,
  children,
}) => {
  const { authentication } = useAuthentication();
  const { cx } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <GlobalSignupModal />
      {isNavbarDisplayed ? (
        <WebOnly>
          <HeaderContainer>
            {isTvBannerDisplayed && <LazyLoad.TVBanner />}
            <Navbar />
          </HeaderContainer>
        </WebOnly>
      ) : null}

      <main className={cx(outfit.className, inter.className)}>
        {authentication?.studentInfo?.hasExpiringCard && (
          <LazyLoad.NotificationMessage
            title={<FormattedMessage {...messages.title} />}
            message={<FormattedMessage {...messages.message} />}
            buttonText={formatMessage(messages.buttonText)}
          />
        )}
        {children}
      </main>
      {isFooterDisplayed && (
        <WebOnly>
          <Footer />
        </WebOnly>
      )}
    </>
  );
};

export default Layout;

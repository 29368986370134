import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import { useFeatureFlagDceDiscounted } from '@/shared/Hooks/featureFlags/useFeatureFlagDceDiscounted';

type DCEDiscountedContextValue = {
  isFeatureFlagDCEDiscountedEnabled: boolean;
};

export const DCEDiscountedContext = createContext<DCEDiscountedContextValue>(
  {} as DCEDiscountedContextValue
);

export const DCEDiscountedProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isFeatureFlagDCEDiscountedEnabled } = useFeatureFlagDceDiscounted();

  const context = useMemo(
    () => ({
      isFeatureFlagDCEDiscountedEnabled,
    }),
    [isFeatureFlagDCEDiscountedEnabled]
  );
  return (
    <DCEDiscountedContext.Provider value={context}>
      {children}
    </DCEDiscountedContext.Provider>
  );
};

export const useDCEDiscountedContext = () => {
  const context = useContext(DCEDiscountedContext);
  if (!context) {
    throw new Error(
      "le context ne peut pas être utilisé si le provider DCEDiscountedContextProvider n'a pas été setté"
    );
  }
  return context;
};

import { useFeatureFlagEnabled } from 'posthog-js/react';

export const useFeatureFlagDceDiscounted = () => {
  const isFeatureFlagDCEDiscountedEnabled =
    useFeatureFlagEnabled('ffDCEDiscounted');

  return {
    isFeatureFlagDCEDiscountedEnabled,
  };
};
